import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { map } from 'rxjs';

import { AuthService } from '@core/services/auth/auth.service';
import { Observable, of } from 'rxjs';
import { tap, shareReplay, filter } from 'rxjs/operators';
import { PermissionData } from '@core/models/permission-data';
import { OrganizationFeatures } from '@core/models/organization-features';

@Injectable({
    providedIn: 'root',
  })
  export class AuthGuard implements CanActivate {
    private permissionData$: Observable<PermissionData>;
  
    constructor(private authService: AuthService, private router: Router) {}
  
    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
      if (!this.authService.isLoggedIn()) {
        this.router.navigate(['/auth/login']);
        return of(false);
      }
  
      const permission = route.data['permission'];
      if (!permission) return of(true);
  
      return this.hasPermission(permission);
    }
  
    private hasPermission(permission: string): Observable<boolean> {
      if (!this.permissionData$) {
        this.permissionData$ = this.authService.getPermissions().pipe(
          // Filter out null values
          filter((data): data is PermissionData => data !== null),
          tap(this.updateAuthServiceFlags.bind(this)),
          shareReplay(1)
        );
      }
  
      return this.permissionData$.pipe(
        map((data) => {
          if (!data) return false;
          
          const permissions = data.Permissions;
          const hasDeclarationModule = data.Organization.HasDeclarationModule;
  
          if (permissions.includes(permission)) {
            if (permission === 'Declaration' && !hasDeclarationModule) {
              this.router.navigate(['dashboard']);
              return false;
            }
            return true;
          } else {
            this.router.navigate(['/']);
            return false;
          }
        })
      );
    }
  
    private updateAuthServiceFlags(data: PermissionData): void {
        const features: Partial<OrganizationFeatures> = {
          hasDeclarationModule: data.Organization.HasDeclarationModule,
          hasAIModule: data.Organization.HasAIModule,
          isDemoInstance: data.Organization.IsDemoInstance,
          hasIATALuggageDatas: data.Organization.HasIATALuggageDatas,
          hasParcelProcess: data.Organization.HasParcelProcess,
          hasLuggageProcess: data.Organization.HasLuggageProcess,
          hasHurricaneModule: data.Organization.HasHurricaneModule,
        };
        this.authService.updateOrganizationFeatures(features);
    }
}