import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ResetDataService {
    private baseUrl = environment.apiUrl + '/api';
    constructor(private http: HttpClient) {}

    resetData() {
        const url = 'DemoManagement/resetdata';
        return this.http.post(`${this.baseUrl}/${url}`, {}, { responseType: 'text' });
    }
}
