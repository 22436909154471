import { CommonModule, DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TokenInterceptorProvider } from '@core/g/interceptor/token.interceptor';
import { UserSession } from '@core/m/user-session';
import { AuthService } from '@core/s/auth/auth.service';
import { TranslateConfigService } from '@core/s/translation/translate-config.service';

export const UserSessionProvider = {
    provide: UserSession,
    useValue: { idle: 5, timeout: 900 },
};

@NgModule({
    declarations: [],
    imports: [CommonModule, HttpClientModule, ReactiveFormsModule, NgbModule],
    providers: [DecimalPipe, AuthService, TranslateConfigService, TokenInterceptorProvider, UserSessionProvider],
})
export class CoreModule {}
