import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ClientConfigService } from '../theme/client-config.service';

@Injectable({
    providedIn: 'root',
})
export class TranslateConfigService {
    onLangChange = this.translate.onLangChange;

    constructor(
        private translate: TranslateService,
        private clientConfigService: ClientConfigService
    ) {
        this.translate.addLangs(['en', 'fr', 'es']);
        this.initializeLanguage();
    }

    private initializeLanguage(): void {
        this.clientConfigService.currentConfig$.subscribe(config => {
            const language = config.language || 'en';
            this.translate.setDefaultLang(language);
            this.translate.use(language);
        });
    }

    changeLanguage(lang: string) {
        this.translate.use(lang);
    }

    get defaultLang(): string {
        return this.translate.defaultLang;
    }

    get(map: string, ...value: string[]): Observable<string> {
        return this.translate.get(map, { value: value[0], value1: value[1] });
    }
}
