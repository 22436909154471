<nav class="navbar navbar-expand-sm navbar-dark  flex-sm-nowrap flex-wrap">
    <div class="container">
        <span class="navbar-brand flex-grow-1">
            <div class="image-container">
                <a href="/"><img *ngIf="clientLogoName" [src]="clientLogoName" class="d-inline-block" alt="TARGET" width="50"/></a>
            </div>
            <div *ngIf="!isWhiteLabel" > 
                <a href="/"><img src="/assets/img/logo.png" class="d-inline-block" alt="SGS" width="70" /></a>
            </div>
        </span>
        <div class="flex-grow-1">
            <ul class="nav navbar-nav justify-content-center" *ngIf="permissions">
                <li class="nav-item mx-1" *ngFor="let permission of permissions">
                    <a class="nav-link" routerLinkActive="active" [routerLink]="permission.link">{{permission.title}}</a>
                </li>

            </ul>
        </div>
        <div class="flex-grow-1">
            <ul class="nav navbar-nav justify-content-end align-items-baseline">
                <button type="button" *ngIf="role === 'Admin' && isDemoInstance" class="btn btn-secondary btn-md mx-3" (click)="openResetDataModel(resetDataModal)" translate>
                    header.resetDataButton
                </button>
                <li class="nav-item" ngbDropdown display="dynamic" placement="bottom-end">
                    <a class="nav-link" ngbDropdownToggle>
                        {{username}}
                    </a>
                    <div ngbDropdownMenu class="bg-charcoal-700">
                        <button ngbDropdownItem class="bg-charcoal-700 text-gray-300 d-flex justify-content-between" (click)="onLogout()" translate>
                            header.LogOutButton
                            <i class="bi bi-chevron-right"></i>
                        </button>
                    </div>
                </li>


                <li class="nav-item" ngbDropdown display="dynamic" placement="bottom-end">
                    <a class="nav-link" ngbDropdownToggle>{{language}}</a>
                    <div ngbDropdownMenu class="bg-charcoal-700">
                        <button ngbDropdownItem class="bg-charcoal-700 text-gray-300 d-flex justify-content-between" (click)="changeLanguage('en')" translate>
                            header.ENLanguageTitle
                        </button>
                        <button ngbDropdownItem class="bg-charcoal-700 text-gray-300 d-flex justify-content-between" (click)="changeLanguage('fr')" translate>
                            header.FRLanguageTitle

                        </button>
                        <button ngbDropdownItem class="bg-charcoal-700 text-gray-300 d-flex justify-content-between" (click)="changeLanguage('es')" translate>
                            header.ESLanguageTitle
                        </button>
                    </div>
                </li>
                <li class="nav-item" ngbDropdown display="dynamic" placement="bottom-end">

                    <a class="nav-link" ngbDropdownToggle>
                        <i class="bi bi-chat-square me-2"></i>
                    </a>
                    <div ngbDropdownMenu class="bg-charcoal-700">
                        <button ngbDropdownItem class="bg-charcoal-700 text-gray-300 d-flex justify-content-between" (click)="open('submit-feedback')" translate>
                            common.submitFeedbackButton
                        </button>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>

<div class="container">
    <ngb-alert [hidden]="hideAlert" type="success" class="text-green-900 bg-green-200" [dismissible]="true" style="margin-top: -30px;">
        <i class="bi bi-check-circle-fill me-2"></i>
        <span [innerHTML]="'create-user.successMessageAfterSubmit' | translate"></span>
    </ngb-alert>
</div>
<div class="bg-secondary">
    <div class="container">
        <ngb-alert [hidden]="hideSubmissionAlert" type="success" class="text-green-900 bg-green-200" [dismissible]="true" style="margin-top: -30px;">
            <i class="bi bi-check-circle-fill me-2"></i>
            <span [innerHTML]="'declaration-details.succesAlert.afterSubmission' | translate:{value: declarationId}"></span>
        </ngb-alert>

        <ngb-alert [hidden]="hideDecisionSubmissionAlert" type="success" class="text-green-900 bg-green-200" [dismissible]="true" style="margin-top: -30px;">
            <i class="bi bi-check-circle-fill me-2"></i>
            <span [innerHTML]="'declaration-details.succesAlert.afterDecisionSubmission' | translate:{value:scanId}"></span>
        </ngb-alert>

    </div>


</div>
<div class="bg-secondary">
    <div class="container">
        <ngb-alert [hidden]="hideCreationAlert" type="success" class="text-green-900 bg-green-200" [dismissible]="true" style="margin-top: -30px;">
            <i class="bi bi-check-circle-fill me-2"></i>
            <span [innerHTML]="'scan-details.succesAlert.afterCreation' | translate:{value:scanId}"></span>
        </ngb-alert>
        <ngb-alert [hidden]="hideUpdateAlert" type="success" class="text-green-900 bg-green-200" [dismissible]="true" style="margin-top: -30px;">
            <i class="bi bi-check-circle-fill me-2"></i>
            <span [innerHTML]="'scan-details.succesAlert.afterUpdate' | translate:{value:scanId}"></span>

        </ngb-alert>
    </div>
</div>

<div class="bg-secondary">
    <div class="container">
        <ngb-alert [hidden]="feedbackFormSubmittedAlert" type="success" class="text-green-900 bg-green-200" [dismissible]="true" style="margin-top: -30px;">
            <i class="bi bi-check-circle-fill me-2"></i>
            <span [innerHTML]="'feedback-form.succesAlert' | translate"></span>
        </ngb-alert>
        <ngb-alert [hidden]="resetDataSubmittedAlert" type="success" class="text-green-900 bg-green-200" [dismissible]="true" style="margin-top: -30px;">
            <i class="bi bi-check-circle-fill me-2"></i>
            <span [innerHTML]="'reset-data.succesAlert' | translate"></span>
        </ngb-alert>
    </div>
</div>

<router-outlet></router-outlet>

<!---data reset Modal -->
<ng-template #resetDataModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" translate>reset-data.resetTitle</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <span translate>reset-data.resetParagraphe</span>
    </div>
    <div class="modal-footer btn-block">
        <button class="btn btn-outline-primary" type="button" (click)="modal.dismiss()" translate>reset-data.cancelButton</button>
        <button class="btn btn-primary" type="button" (click)="resetPlateformData()" translate>reset-data.confirmButton</button>
    </div>
</ng-template>