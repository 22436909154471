export interface Permission {
    title: string;
    permission: string;
    link: string;
}

export const PERMISSIONS: Permission[] = [
    {
        title: 'Dashboard',
        permission: 'Dashboard',
        link: '/dashboard',
    },
    { title: 'User Management', permission: 'IsAdmin', link: '/admin' },
    { title: 'Scans', permission: 'Scans', link: '/scans' },
    { title: 'Declarations', permission: 'Declaration', link: '/declarations' },
];

//Admin : Dashboard - ISAdmin- Scans- Declaration- ScanDetails
//Inspector : Dashboard - Declaration -ScanDetails
//Analyst : Dashbord,Scans ,ScanDetails
