import { NgModule } from '@angular/core';
import { Router, RouterModule, ROUTES } from '@angular/router';

import { AuthGuard } from '@core/g/auth/auth.guard';
import { AuthService } from '@core/services/auth/auth.service';

import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';

function routesFactory(authService: AuthService, router: Router) {
    return [
        { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
        {
            path: 'auth',
            loadChildren: () => import('./features/auth/auth.module').then((m) => m.AuthModule),
        },
        {
            path: '',
            component: MainLayoutComponent,
            children: [
                {
                    path: 'dashboard',
                    loadChildren: () => {
                        return authService
                            .getRole()
                            .then(
                                (role) => {
                                    switch (role) {
                                        case 'Admin':
                                            return import('./features/dashboard-admin/dashboard-admin.module').then(
                                                (m) => m.DashboardAdminModule
                                            );
                                        case 'Inspector':
                                            return import(
                                                './features/dashboard-inspector/dashboard-inspector.module'
                                            ).then((m) => m.DashboardInspectorModule);
                                        case 'Analyst':
                                            return import('./features/dashboard-analyst/dashboard-analyst.module').then(
                                                (m) => m.DashboardAnalystModule
                                            );
                                        default: {
                                            authService.logout();
                                            return;
                                        }
                                    }
                                },
                                (err) => {
                                    console.log(err);
                                }
                            )
                            .catch((error) => {
                                console.log(error);
                                authService.logout();
                                return;
                            });
                    },
                    canActivate: [AuthGuard],
                },
                {
                    path: 'admin',
                    loadChildren: () => import('./features/user-management/user-management.module').then((m) => m.UserManagementModule),
                    data: {
                        permission: 'IsAdmin',
                    },
                    canActivate: [AuthGuard],
                },
                {
                    path: 'scans',
                    loadChildren: () => import('./features/scans/scans.module').then((m) => m.ScansModule),
                    data: {
                        permission: 'Scans',
                    },
                    canActivate: [AuthGuard],
                },
                {
                    path: 'declarations',
                    loadChildren: () => import('./features/declarations/declarations.module').then((m) => m.DeclarationsModule),
                    data: {
                        permission: 'Declaration',
                    },
                    canActivate: [AuthGuard],
                },
                {
                    path: '**',
                    redirectTo: 'dashboard',
                },
            ],
            canActivate: [AuthGuard],
        },
        {
            path: '**',
            redirectTo: '',
        },
    ];
}

@NgModule({
    imports: [RouterModule.forRoot([], { scrollPositionRestoration: 'enabled' })],
    providers: [
        {
            provide: ROUTES,
            useFactory: routesFactory,
            multi: true,
            deps: [AuthService],
        },
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
