import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable } from 'rxjs';

import { AuthService } from '@core/s/auth/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private router: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let authReq = req;
        const token = this.authService.getToken();
        if (token != null) {
            authReq = req.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token,
                    //'Content-Type': 'application/json',
                },
            });
        }
        return next.handle(authReq).pipe(
            catchError((err) => {
                //handling the token expiration --> to change to 403
                if (err.status && err.status === 401) {
                    // console.log(err.status);
                    this.authService.logout();
                    this.router.navigate(['/auth/login']).then(() => {
                        window.location.reload();
                    });
                }
                console.log('error in source. Details: ');
                throw err;
            })
        );
    }
}

export const TokenInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
};
