import { Component, OnInit, HostBinding } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  Router,
  // import as RouterEvent to avoid confusion with the DOM Event
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router'
import { Title } from '@angular/platform-browser';
import { ThemeService } from './core/services/theme/theme.service';
import { ClientConfig, ClientConfigService } from '@core/services/theme/client-config.service';
import { TranslateConfigService } from '@core/services/translation/translate-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @HostBinding('class') 
  get themeClass() {
    return this.currentTheme;
  }
  
  currentTheme = 'default';

  private currentConfig: ClientConfig;

  constructor(
    private translateService: TranslateConfigService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private titleService: Title,
    private themeService: ThemeService,
    private clientConfigService: ClientConfigService
  ) {

    this.clientConfigService.currentConfig$.subscribe(config => {
      this.currentConfig = config;
    });

    this.themeService.getActiveTheme().subscribe(theme => {
      this.currentTheme = theme;
    });
    
    this.router.events.subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e);
    });
  }

    ngOnInit() {
      this.setFavicon();
      this.setDynamicTitle();
    }

    setFavicon() {
      let link: HTMLLinkElement = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = this.currentConfig.favicon;

      document.getElementsByTagName('head')[0].appendChild(link);
    }

    setDynamicTitle() {
      const defaultTitle = 'D-TECT Parcel'; 
      const customTitle = this.currentConfig.title || defaultTitle;
      this.titleService.setTitle(customTitle);
    }

    navigationInterceptor(event: RouterEvent): void {
      if (event instanceof NavigationStart) {
        if (event.url !== '/auth/login') {
          this.spinner.show();
        }  
      }
      if (event instanceof NavigationEnd) {
        this.spinner.hide();
      }
  
      // Set loading state to false in both of the below events to hide the spinner in case a request fails
      if (event instanceof NavigationCancel) {
        this.spinner.hide();
      }
      if (event instanceof NavigationError) {
        this.spinner.hide();
      }
    }
}
