import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { MailService } from '@core/services/mail/mail.service';
import { TranslateConfigService } from '@core/services/translation/translate-config.service';

const MAX_SIZE_FILE = 2097152;
@Component({
    selector: 'app-submit-feedback',
    templateUrl: './submit-feedback.component.html',
    styleUrls: ['./submit-feedback.component.scss'],
})
export class SubmitFeedbackComponent implements OnInit {
    errorMessage: boolean;
    submitForm: FormGroup;

    submitted = false;
    maxSizeFile = MAX_SIZE_FILE;
    files: any = [];

    @Input() email: string;
    constructor(
        private translateService: TranslateConfigService,
        public activeModal: NgbActiveModal,
        private submitFormBuilder: FormBuilder,
        private modalService: NgbModal,
        private router: Router,
        private mailService: MailService
    ) {
        this.submitForm = this.submitFormBuilder.group({
            email: new FormControl({ value: '', disabled: true }),
            notes: new FormControl('', [Validators.required]),
            images: new FormControl([]),
        });
    }
    ngOnInit(): void {
        this.submitForm.controls['email'].setValue(this.email);
    }
    onSubmit() {
        this.files = this.checkFilesSize();
        this.submitForm.controls['images'].setValue(this.files);

        this.submitted = true;
        this.errorMessage = false;
        if (this.submitForm.valid) {
            this.submitted = false;

            const payload = {
                email: this.email,
                ...this.submitForm.value,
            };
            this.mailService.submitFeedback(payload).subscribe({
                next: (res) => {
                    this.router
                        .navigate([''], {
                            state: {
                                origin: 'feedback-submitted',
                            },
                        })
                        .then(() => {
                            this.modalService.dismissAll();
                            window.location.reload();
                        });
                },
                error: (err) => {
                    console.log(err);
                },
            });
        } else {
            this.errorMessage = true;
        }
    }

    /**
     * on file drop handler
     */
    onFileDropped($event) {
        this.prepareFilesList($event);
    }

    /**
     * handle file from browsing
     */
    fileBrowseHandler($event) {
        const files = $event.target.files;
        this.prepareFilesList(files);
    }

    /**
     * Delete file from files list
     * @param index (File index)
     */
    deleteFile(index: number) {
        this.files.splice(index, 1);
    }

    /**
     * Convert Files list to normal array list
     * @param files (Files List)
     */
    prepareFilesList(files: Array<any>) {
        for (const item of files) {
            const reader = new FileReader();

            reader.onload = () => {
                //console.log(reader.result as string);
            };
            reader.readAsDataURL(item);
            this.files.push(item);
        }

        // console.log(this.files);
    }

    //submit files only if (item?.size <= this.maxSizeFile)

    checkFilesSize() {
        const files: Array<any> = [];
        for (const item of this.files) {
            if (item?.size <= this.maxSizeFile) files.push(item);
        }

        return files;
    }
}
