import { Location } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@core/s/auth/auth.service';
import { TranslateConfigService } from '@core/s/translation/translate-config.service';
import { ResetDataService } from '@core/services/db/reset-data.service';
import { SubmitFeedbackComponent } from '@shared/c/submit-feedback/submit-feedback.component';
import { Permission, PERMISSIONS } from '@shared/u/permission';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClientConfig } from '@core/services/theme/client-config.service';
import { ClientConfigService } from '@core/services/theme/client-config.service';

@Component({
    selector: 'app-main-layout',
    templateUrl: './main-layout.component.html',
    styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
    @ViewChild('resetDataModal') resetDataModal: TemplateRef<NgbModal>;
    resetDataModalRef: NgbModalRef;

    permissions: Permission[] = [];
    authPermissions: string[] = [];
    token: string | null;
    username: string | null;
    language: string | null;
    hideSubmissionAlert = true;
    hideAlert = true;
    hideCreationAlert = true;
    hideUpdateAlert = true;
    hideDecisionSubmissionAlert = true;
    feedbackFormSubmittedAlert = true;
    resetDataSubmittedAlert = true;
    role: string;
    scanId: string;
    declarationId: string;
    hasDeclarationModule = true;
    isDemoInstance = true;
    isWhiteLabel: boolean;
    clientLogoName: string;
    currentConfig: ClientConfig;
 
    constructor(
        private translateService: TranslateConfigService,
        private authService: AuthService,
        private router: Router,
        private location: Location,
        private modalService: NgbModal,
        private spinner: NgxSpinnerService,
        private resetDataService: ResetDataService,
        private clientConfigService: ClientConfigService
    ) {

        this.clientConfigService.currentConfig$.subscribe(config => {
            this.currentConfig = config;
            this.isWhiteLabel = config.isWhiteLabel;
            this.clientLogoName = config.clientLogo;
        });

        const state = this.router.getCurrentNavigation()?.extras.state;

        if (state) {
            if (state['origin'] === 'analyse-declaration') {
                this.declarationId = state['declarationId'];
                this.hideSubmissionAlert = false;
                setTimeout(() => (this.hideSubmissionAlert = true), 4000);
                this.location.replaceState('/dashboard');
            }

            if (state['origin'] === 'analyse-scan-by-inspector') {
                this.scanId = state['scanId'];
                this.declarationId = state['declarationId'];
                this.hideDecisionSubmissionAlert = false;
                setTimeout(() => (this.hideDecisionSubmissionAlert = true), 4000);
                this.location.replaceState('/declarations/declaration-details/' + this.declarationId);
            }

            if (state && state['origin'] === 'create-user') {
                this.hideAlert = false;
                setTimeout(() => (this.hideAlert = true), 4000);
                this.location.replaceState('/admin');
            }

            if (state && state['origin'] === 'edit-scan') {
                if (state['isNew'] === true) {
                    this.hideCreationAlert = false;
                    this.scanId = state['scanId'];
                    setTimeout(() => (this.hideCreationAlert = true), 4000);
                } else if (state['isNew'] === false) {
                    this.hideUpdateAlert = false;
                    this.scanId = state['scanId'];
                    setTimeout(() => (this.hideUpdateAlert = true), 4000);
                }

                this.location.replaceState('/scans/scan-details/' + this.scanId);
            }

            if (state && state['origin'] === 'feedback-submitted') {
                this.feedbackFormSubmittedAlert = false;
                setTimeout(() => (this.feedbackFormSubmittedAlert = true), 4000);
                this.location.replaceState('/dashboard');
            }

            if (state && state['origin'] === 'reset-data') {
                this.resetDataSubmittedAlert = false;
                setTimeout(() => (this.resetDataSubmittedAlert = true), 4000);
                this.location.replaceState('/dashboard');
            }
        }
    }

    ngOnInit() {
        this.username = this.authService.getUsername();
        this.authService.getPermissions().subscribe((data) => {
            this.authPermissions = data?.Permissions;
            this.role = data?.Role;
            this.isDemoInstance = data?.Organization?.IsDemoInstance;
            this.hasDeclarationModule = data?.Organization?.HasDeclarationModule;
            this.changeLanguage(this.authService.getLanguage() ?? this.currentConfig.language!);
        });
    }

    onLogout() {
        this.authService.logout();
        this.router.navigate(['/auth/login']).then(() => {
            window.location.reload();
        });
    }

    changeLanguage(language: string) {
        // Show the spinner
        this.spinner.show();

        this.language = language.toUpperCase();
        
        //change the language
        this.translateService.changeLanguage(language.toLowerCase());
        this.authService.saveLanguage(language.toLowerCase());
        this.setUserPermissions();

        // Hide the spinner
         this.spinner.hide();
    }

    open(component?: string) {
        if (component === 'submit-feedback') {
            const modalRef = this.modalService.open(SubmitFeedbackComponent, {
                size: 'lg',
                modalDialogClass: this.currentConfig.theme,
                windowClass: this.currentConfig.theme
            });
            modalRef.componentInstance.email = this.authService.getEmail();
        }
    }

    setUserPermissions() {
        this.permissions = [];
        for (let item of PERMISSIONS) {
            if (this.authPermissions.includes(item.permission)) {
                if (item.permission === 'Declaration' && !this.hasDeclarationModule) {
                    continue;
                }
                this.translateService.get('header.' + item.permission + 'Title').subscribe({
                    next: (value) => {
                        item.title = value;
                        this.permissions.push(item);
                    },
                    error: (err) => console.log(err),
                });
            }
        }
    }

    openResetDataModel(content: TemplateRef<NgbModal>) {
        this.resetDataModalRef = this.modalService.open(content, { 
            size: 'lg',             
            modalDialogClass: this.currentConfig.theme,
            windowClass: this.currentConfig.theme
        });
    }

    resetPlateformData() {
        this.resetDataService.resetData().subscribe({
            next: (res) => {
                this.router
                    .navigate([''], {
                        state: {
                            origin: 'reset-data',
                        },
                    })
                    .then(() => {
                        this.modalService.dismissAll();
                        window.location.reload();
                    });
            },
            error: (err) => {
                console.log(err);
            },
        });
    }
}
