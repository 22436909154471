import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    private baseUrl = environment.apiUrl + '/api';
    private httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    constructor(private http: HttpClient) {}

    get(url: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/${url}`, this.httpOptions);
    }

    post(url: string, body: object): Observable<any> {
        return this.http.post(`${this.baseUrl}/${url}`, body, this.httpOptions);
    }

    put(url: string, body: object) {
        return this.http.put(`${this.baseUrl}/${url}`, body, this.httpOptions);
    }

    delete(url: string) {
        return this.http.delete(`${this.baseUrl}/${url}`, this.httpOptions);
    }

    postBlobFiles(url: string, body: object): Observable<any> {
        return this.http.post(`${this.baseUrl}/${url}`, body, {
            responseType: 'blob', //responseType?: 'arraybuffer' | 'blob' | 'json' | 'text'
        });
    }

    postFiles(url: string, body: object): Observable<any> {
        return this.http.post(`${this.baseUrl}/${url}`, body);
    }
}
