import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';

export interface ClientConfig {
  id: string;
  theme: string;
  clientLogo: string;
  favicon: string;
  title: string;
  isWhiteLabel: boolean;
  language?: string;
  authMethod?: string; //'Identity' Or 'LDAP'
}

@Injectable({
  providedIn: 'root'
})
export class ClientConfigService {
  private readonly clients: { [key: string]: ClientConfig } = {
    htds: {
      id: 'htds',
      theme: 'htds-theme',
      clientLogo: `/assets/img/htds-logo.png`,
      favicon: '/assets/img/favicon-htds.png',
      title: 'HTDS - D-TECT Parcel',
      isWhiteLabel: true,
      language: 'fr',
      authMethod: 'Identity'
    },
    target: {
      id: 'target',
      theme: 'default',
      clientLogo: '/assets/img/target-logo.png',
      favicon: '/assets/img/favicon-target.png',
      title: 'TARGET - D-TECT Parcel',
      isWhiteLabel: false,
      language: 'es',
      authMethod: 'Identity'
    },
    spain: {
      id: 'spain',
      theme: 'default',
      clientLogo: '/assets/img/target-logo.png',
      favicon: '/assets/img/favicon-target.png',
      title: 'TARGET - D-TECT Parcel',
      isWhiteLabel: false,
      language: 'es',
      authMethod: 'LDAP'
    },
    sps: {
      id: 'sps',
      theme: 'default',
      clientLogo: '/assets/img/sps-logo.png',
      favicon: '/assets/img/sps-logo.png',
      title: 'SPS - D-TECT Parcel',
      isWhiteLabel: false,
      language: 'en',
      authMethod: 'Identity'
    },
    default: {
      id: 'default',
      theme: 'default',
      clientLogo: '',
      favicon: '/assets/img/favicon2.png',
      title: 'D-TECT Parcel',
      isWhiteLabel: false,
      language: 'en',
      authMethod: 'Identity'
    }
  };

  private currentConfigSubject = new BehaviorSubject<ClientConfig>(this.clients['default']);
  currentConfig$: Observable<ClientConfig> = this.currentConfigSubject.asObservable();

  constructor() {
    this.initializeConfig();
  }

  private initializeConfig(): void {
    const clientId = environment.clientName || 'default';
    this.setClientConfig(clientId);
  }

  setClientConfig(clientId: string): void {
    const config = this.getClientConfig(clientId);
    this.currentConfigSubject.next(config);
    this.applyTheme(config.theme);
  }

  getClientConfig(clientId: string): ClientConfig {
    return this.clients[clientId] || this.clients['default'];
  }

  private applyTheme(theme: string): void {
    document.body.className = theme;
  }
}