<div class="modal-header">
    <div class="modal-title">
        <h4 translate> feedback-form.title</h4>
        <small translate>feedback-form.subtitle</small>
        <div class="alert alert-danger my-2" role="alert" *ngIf="errorMessage" translate>
            <i class="bi bi-exclamation-triangle-fill me-2"></i> feedback-form.errorMessage
        </div>
    </div>
    <button type="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
    <form [formGroup]="submitForm">
        <div class="form-group mb-4">
            <label for="email" class="form-label" translate>feedback-form.emailLabel</label><br>
            <input type="text" class="form-control" formControlName="email" id="email" />
            <small translate> feedback-form.emailNote</small>
        </div>
        <div class="form-group mb-3">
            <label for="notes" class="form-label" translate>feedback-form.notesLabel</label>
            <textarea type="text" class="form-control" formControlName="notes" id="notes" required
                placeholder="Please write down your ideas or suggestions..." [ngClass]="{ 
                    'is-invalid': (submitted || submitForm.controls['notes'].touched) && submitForm.controls['notes'].errors,
            'is-valid': (submitted || submitForm.controls['notes'].touched) && !submitForm.controls['notes'].errors }">
            </textarea>
        </div>

        <div class="form-group mb-3">
            <label for="fileDropRef" translate>feedback-form.imageProofLabel</label>
            <div class="drag-and-drop-container" appDnd (fileDropped)="onFileDropped($event)" *ngIf="files.length <= 5">
                <img src="/assets/icons/drag-and-drop.svg" alt="drag and drop">
                <br><br>
                <input type="file" accept="image/*" #fileDropRef id="fileDropRef" multiple
                    (change)="fileBrowseHandler($event)" />
                <h6 translate>feedback-form.imageArea.title</h6>
                <small translate>feedback-form.imageArea.subtitle</small>
            </div>

            <div class="files-list">
                <div class="single-file" [ngClass]="{'error': file?.size > maxSizeFile}"
                    *ngFor="let file of files; let i = index">
                    <div class="info">
                        <span class="name">
                            <i class="bi bi-file-earmark me-1"></i> {{ file?.name }}
                        </span>
                    </div>
                    <div class="delete" (click)="deleteFile(i)">
                        <i class="bi bi-trash"></i>
                    </div>
                </div>
            </div>
            <small [ngClass]="{'text-danger': files.length > 5}" translate>feedback-form.imageArea.note</small>
        </div>

    </form>
</div>
<div class="modal-footer btn-block">
    <button class="btn btn-outline-primary" type="button" translate
        (click)="activeModal.dismiss()">feedback-form.discardButton</button>
    <button class="btn btn-primary" type="button" (click)="onSubmit()" [disabled]="!submitForm.valid"
        translate>feedback-form.submitButton</button>
</div>