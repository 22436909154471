import { Injectable } from '@angular/core';

import { ApiService } from '@core/s/api/api.service';

@Injectable({
    providedIn: 'root',
})
export class MailService {
    constructor(private api: ApiService) {}

    submitFeedback(payload: any) {
        const formData = new FormData();

        formData.append('Message', payload.notes);

        payload.images.forEach((file) => {
            formData.append('Files', file);
        });

        return this.api.postFiles('Mail/sendFeedback', formData);
    }
}
