import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError  } from 'rxjs';
import { ApiService } from '@core/s/api/api.service';
import { catchError, map } from 'rxjs/operators';
import { OrganizationFeatures } from '@core/models/organization-features';
import { PermissionData } from '@core/models/permission-data';

const TOKEN_KEY = 'auth-token';
const EMAIL_KEY = 'auth-email';
const USERNAME_KEY = 'auth-username';
const LANGUAGE_KEY = 'auth-language';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private organizationFeatures = new BehaviorSubject<OrganizationFeatures>({
        hasDeclarationModule: false,
        hasAIModule: false,
        isDemoInstance: false,
        hasIATALuggageDatas: false,
        hasParcelProcess: false,
        hasLuggageProcess: false,
        hasHurricaneModule: false
      });

    constructor(private api: ApiService) {}

    loginWithUserName(username: string, password: string): Observable<any> {
        const payload = {
            UserName: username,
            Password: password,
        };
        return this.api.post('Authentication/login', payload);
    }

    loginWithEmail(email: string, password: string): Observable<any> {
        const payload = {
            Email: email,
            Password: password,
        };
        return this.api.post('Authentication/login', payload);
    }

    logout(): void {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(EMAIL_KEY);
        localStorage.removeItem(USERNAME_KEY);
        localStorage.removeItem(LANGUAGE_KEY);
    }

    isLoggedIn(): boolean {
        const token = localStorage.getItem(TOKEN_KEY);
        return token !== null && token.length > 0;
    }

    saveToken(token: string): void {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.setItem(TOKEN_KEY, token);
    }

    getToken(): string | null {
        return this.isLoggedIn() ? localStorage.getItem(TOKEN_KEY) : null;
    }

    saveEmail(email: string): void {
        localStorage.removeItem(EMAIL_KEY);
        localStorage.setItem(EMAIL_KEY, email);
    }

    getEmail(): string | null {
        return localStorage.getItem(EMAIL_KEY) ? localStorage.getItem(EMAIL_KEY) : null;
    }

    saveUsername(username: string): void {
        localStorage.removeItem(USERNAME_KEY);
        localStorage.setItem(USERNAME_KEY, username);
    }

    getUsername(): string | null {
        return localStorage.getItem(USERNAME_KEY) ? localStorage.getItem(USERNAME_KEY) : null;
    }

    getPermissions() {
        return this.api.get(`Permission/getPermissions`);
    }

    getRole(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.getPermissions().subscribe((data) => {
                const role = data?.Role;
                const hasDeclarationModule = data?.Organization?.HasDeclarationModule;
                this.updateAuthServiceFlags(data);
                if (role) {
                    if (role === 'Inspector' && !hasDeclarationModule) {
                        return reject('Access denied for Inspector');
                    }
                    if (role !== 'Operator') return resolve(role);
                } else return reject('No role found for this user');
            });
        });
    }

    private updateAuthServiceFlags(data: PermissionData): void {
        const features: Partial<OrganizationFeatures> = {
          hasDeclarationModule: data.Organization.HasDeclarationModule,
          hasAIModule: data.Organization.HasAIModule,
          isDemoInstance: data.Organization.IsDemoInstance,
          hasIATALuggageDatas: data.Organization.HasIATALuggageDatas,
          hasParcelProcess: data.Organization.HasParcelProcess,
          hasLuggageProcess: data.Organization.HasLuggageProcess,
          hasHurricaneModule: data.Organization.HasHurricaneModule,
        };
        this.updateOrganizationFeatures(features);
    }

    getRole$(): Observable<string> {
        return this.getPermissions().pipe(
            map(data => {
                const role = data?.Role;
                const hasDeclarationModule = data?.Organization?.HasDeclarationModule;
    
                if (role) {
                    if (role === 'Inspector' && !hasDeclarationModule) {
                        throw new Error('Access denied for Inspector');
                    }
                    if (role !== 'Operator') return role;
                } else {
                    throw new Error('No role found for this user');
                }
            }),
            catchError(err => {
                // Handle or rethrow the error as you see fit
                console.error(err);
                return throwError(err);
            })
        );
    }
    
    saveLanguage(language: string): void {
        localStorage.removeItem(LANGUAGE_KEY);
        localStorage.setItem(LANGUAGE_KEY, language);
    }

    getLanguage(): string | null {
        return localStorage.getItem(LANGUAGE_KEY) ? localStorage.getItem(LANGUAGE_KEY) : null;
    }

    updateOrganizationFeatures(features: Partial<OrganizationFeatures>): void {
        console.log('Updating organization features:', features);
        console.trace();
        this.organizationFeatures.next({
          ...this.organizationFeatures.value,
          ...features
        });
    }

    getMultipleFeatures(...features: (keyof OrganizationFeatures)[]): Observable<Partial<OrganizationFeatures>> {
        return this.organizationFeatures.pipe(
          map(allFeatures => {
            const result: Partial<OrganizationFeatures> = {};
            features.forEach(feature => {
              result[feature] = allFeatures[feature];
            });
            return result;
          })
        );
    }
    
    getOrganizationFeatures(): Observable<OrganizationFeatures> {
        return this.organizationFeatures.asObservable();
    }
    
    getFeature(feature: keyof OrganizationFeatures): Observable<boolean> {
        return this.organizationFeatures.pipe(
          map(features => features[feature])
        );
    }
}
