import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { ClientConfigService } from './client-config.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private activeTheme = new BehaviorSubject<string>('default');

  constructor(private clientConfigService: ClientConfigService) {
    this.initializeTheme();
  }

  private initializeTheme(): void {
    const clientId = environment.clientName;
    this.setTheme(clientId);
  }

  setTheme(clientId: string) {
    const config = this.clientConfigService.getClientConfig(clientId);
    this.activeTheme.next(config.theme);
  }

  getActiveTheme(): Observable<string> {
    return this.activeTheme.asObservable();
  }
}